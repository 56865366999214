// var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
var megaMenu = true;

//var carouselBackgroundVideoYouTubeID = 'flMhxQN7jPE'; // see https://fresco-docs.netlify.com/#/carousel/background-video
// $('.carouselSlide:first-child .carouselSlideTitle').append(
//   '<div class="yt-container"><iframe src="https://www.youtube.com/embed/flMhxQN7jPE?controls=0&rel=0&amp;autoplay=1&mute=1&loop=1&disablekb=1&fs=0&modestbranding=1&start=53&playlist=flMhxQN7jPE" frameborder="0" allowfullscreen></iframe></div>'
// );

var countUpStats = true;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

var feedTitleLinks = ['/blogs/blog', '/Pages/Events/Category/training-events', '/news']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 10000; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

var sidebarCards = true; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// $(".menuMain").insertBefore(".headerContent");
$(".headerContent a.mainLogo").insertBefore(".headerContent .helpline");
$(".homeFeatureDetailsWrapper p").append("<span class='readMore homeFeaturesReadMore'>Read more</span>");

// Sticky nav
$(window).scroll(function() {
    
  //var globalAlertHeight = $('.GlobalAlert').outerHeight(true);
  if ($('.userLoggedIn').length) {
      var headerHeight = $('.headerContent').outerHeight(true) + $('.adminBarEdit').outerHeight(true);
  } else {
      var headerHeight = $('.headerContent').outerHeight(true);
  }

  var body = $('body');
  var scroll = $(window).scrollTop(); 

  if (scroll >= headerHeight) {
      body.addClass("bodyScrolled");
  } else {
      body.removeClass("bodyScrolled");
  }
}); 

// Vertically align .readMore for all cards
$(window).on('load resize scroll', function () {

  $('.homeFeatures .homeFeature, .feedItem, .listedPost').each(function () {
    var $heading = $(this).find('h2, h3');
    var headingHeight = $heading.outerHeight(true);
    var $summaryText  = $(this).find('p');

    $summaryText.css({'height' : 'calc(100% - ' + headingHeight + 'px) ' });

  })
});

// Add class to sidebar content posts so they can use card prefab
if (typeof sidebarCards !== "undefined" && sidebarCards) {
  if ($("section.associatedBox")) {
    $('.associatedBox').wrapAll('<div class="associatedBoxWrapper">');
    $('.associatedBoxWrapper').wrapAll('<div class="associatedBoxWrapperOuter">');
    $("section.associatedBox").each(function () {
      $(this).addClass("listedPost").addClass("sidebarCard");
      $(this).find("h2, p").wrapAll('<div class="listedPostText">');
      let thisURL = $(this).find("h2 a").attr("href");
      $(this).attr("onclick", `window.location = "${thisURL}"`);
      $(this)
        .find("h3")
        .replaceWith(function () {
          return "<h2>" + $(this).text() + "</h2>";
        });
    });
    $("section.associatedBox").each(function () {
      $(this).find("> a").addClass('.associated-image-link');
    });
    $(".associatedBoxWrapper").prepend('<h2>Related resources and information</h2>');

  }
}

// Move associated posts above related
$(".associatedBoxWrapperOuter").insertBefore(".associatedRelatedPosts");
$(".associatedRelatedPosts h2").text("Featured");









